import BaseAPI from '../apiBase';
import config from '../../../config';

class EmployeeAPI extends BaseAPI {
  constructor() {
    super(config.EMPLOYEE_API_URL!);
  }

  getEmployeeDetails(employeeId: string) {
    return this.get(`/employees/${employeeId}`);
  }

  getEmployeies() {
    return this.get(`/employees`);
  }
}

export { EmployeeAPI };
