import { APICore, createAPICore } from '../../helpers/api/apiCore';
import { AbsenceActionTypes } from './constants';
import { IAbsence } from '../../models/types/types';
import config from 'config';

const api = createAPICore(config.ABSENCE_API_URL!);

const INIT_STATE = {
  data: [],
  loading: false,
};
type AbsenceActionType = {
  type:
    | AbsenceActionTypes.DATA
    | AbsenceActionTypes.API_RESPONSE_SUCCESS
    | AbsenceActionTypes.API_RESPONSE_ERROR;
  payload: {
    data: [];
  };
};

type State = {
  data?: IAbsence[] | [];
  loading?: boolean;
  value?: boolean;
};

const Absence = (state: State = INIT_STATE, action: AbsenceActionType) => {
  switch (action.type) {
    case AbsenceActionTypes.API_RESPONSE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default Absence;
