import { IEmployee } from '../../models/types/types';
import { AxiosResponse } from 'axios';
import { createAPICore } from './apiCore';
import config from 'config';

const api = createAPICore(config.EMPLOYEE_API_URL!);

function getAll(): Promise<AxiosResponse<IEmployee[]>> {
  const baseUrl = '/api/v1/employee/';
  return api.get(`${baseUrl}`, undefined);
}

export { getAll };
