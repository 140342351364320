import { ProfileActionTypes } from './constants';

const INIT_STATE = {
  data: {},
  loading: false,
};

type CapacityProfile = {
  id: string;
  employeeId: string;
  validFrom: string;
  mondayStart: string;
  mondayHours: string;
  tuesdayStart: string;
  tuesdayHours: string;
  wednesdayStart: string;
  wednesdayHours: string;
  thursdayStart: string;
  thursdayHours: string;
  fridayStart: string;
  fridayHours: string;
  saturdayStart: string;
  saturdayHours: string;
  sundayStart: string;
  sundayHours: string;
  totalHours: number;
};

type ProfileActionType = {
  type:
    | ProfileActionTypes.PROFILE
    | ProfileActionTypes.API_RESPONSE_SUCCESS
    | ProfileActionTypes.API_RESPONSE_ERROR;
  payload: {
    data: {};
  };
};

type State = {
  data?: CapacityProfile | {};
  loading?: boolean;
  value?: boolean;
};

const Profile = (state: State = INIT_STATE, action: ProfileActionType) => {
  switch (action.type) {
    case ProfileActionTypes.API_RESPONSE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default Profile;
