import { APICore, createAPICore } from './apiCore';
import { ILoginRequest } from '../../models/types/types';
import config from 'config';

const api = createAPICore(config.IDENTITY_API_URL!);

function profile() {
  const baseUrl = '/api/capacity/';
  return api.get(`${baseUrl}`, undefined);
}

export { profile };
