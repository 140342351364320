// Define types for roles and menu items
export type Role =
  | 'Applicant'
  | 'Recruiter'
  | 'HRAdmin'
  | 'JobPoster'
  | 'HiringManager'
  | 'OnboardingAdmin'
  | 'Employee'
  | 'PayrollOfficer'
  | 'Management';

export type MenuItemType = {
  key: string;
  label: string;
  isTitle?: boolean;
  icon?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  parentKey?: string;
  target?: string;
  roles: Role[]; // Roles that can access this menu item
  children?: MenuItemType[]; // Nested menu items
};

// Define the menu structure
const MENU_ITEMS: MenuItemType[] = [
  {
    key: 'dashboards',
    label: 'Dashboard',
    isTitle: false,
    url: '/dashboard',
    roles: ['Applicant', 'Recruiter', 'HRAdmin', 'Management'],
  },
  {
    key: 'jobs',
    label: 'Job Postings',
    isTitle: false,
    roles: ['Applicant', 'Recruiter', 'JobPoster'],
    children: [
      {
        key: 'view-jobs',
        label: 'View Jobs',
        url: '/jobs/view',
        roles: ['Applicant'],
      },
      {
        key: 'create-job',
        label: 'Create Job',
        url: '/jobs/create',
        roles: ['JobPoster'],
      },
      {
        key: 'manage-jobs',
        label: 'Manage Jobs',
        url: '/jobs/manage',
        roles: ['Recruiter'],
      },
    ],
  },
  {
    key: 'applications',
    label: 'Applications',
    isTitle: false,
    roles: ['Recruiter', 'HiringManager'],
    children: [
      {
        key: 'review-applications',
        label: 'Review Applications',
        url: '/applications/review',
        roles: ['Recruiter'],
      },
      {
        key: 'assign-applications',
        label: 'Assign Applications',
        url: '/applications/assign',
        roles: ['HiringManager'],
      },
    ],
  },
  {
    key: 'onboarding',
    label: 'Onboarding',
    isTitle: false,
    roles: ['OnboardingAdmin'],
    children: [
      {
        key: 'onboarding-tasks',
        label: 'Onboarding Tasks',
        url: '/onboarding/tasks',
        roles: ['OnboardingAdmin'],
      },
      {
        key: 'track-progress',
        label: 'Track Progress',
        url: '/onboarding/progress',
        roles: ['OnboardingAdmin'],
      },
    ],
  },
  {
    key: 'profile',
    label: 'Profile',
    isTitle: false,
    roles: ['Applicant', 'Employee', 'HRAdmin'],
    children: [
      {
        key: 'edit-profile',
        label: 'Edit Profile',
        url: '/profile/edit',
        roles: ['Applicant', 'Employee'],
      },
      {
        key: 'view-profile',
        label: 'View Profile',
        url: '/profile/view',
        roles: ['HRAdmin'],
      },
    ],
  },
  {
    key: 'reports',
    label: 'Reports',
    isTitle: false,
    roles: ['HRAdmin', 'Management'],
    children: [
      {
        key: 'recruitment-reports',
        label: 'Recruitment Reports',
        url: '/reports/recruitment',
        roles: ['HRAdmin'],
      },
      {
        key: 'financial-reports',
        label: 'Financial Reports',
        url: '/reports/financial',
        roles: ['Management'],
      },
    ],
  },
  {
    key: 'documents',
    label: 'Documents',
    isTitle: false,
    roles: ['Employee', 'HRAdmin', 'Management'],
    children: [
      {
        key: 'view-documents',
        label: 'View Documents',
        url: '/documents/view',
        roles: ['Employee', 'HRAdmin'],
      },
      {
        key: 'upload-documents',
        label: 'Upload Documents',
        url: '/documents/upload',
        roles: ['HRAdmin'],
      },
      {
        key: 'manage-documents',
        label: 'Manage Documents',
        url: '/documents/manage',
        roles: ['HRAdmin', 'Management'],
      },
    ],
  }, {
    key: 'administration',
    label: 'Administration',
    isTitle: false,
    roles: ['HRAdmin', 'Management'],
    children: [
      {
        key: 'modules',
        label: 'Modules',
        url: '/administration/modules',
        roles: ['HRAdmin', 'Management'],
      },
      {
        key: 'manage-roles',
        label: 'Manage Roles',
        url: '/administration/roles',
        roles: ['Management'],
      },
    ],
  }
];

export { MENU_ITEMS };
