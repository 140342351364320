import axios, { AxiosInstance } from 'axios';

class BaseAPI {
  protected client: AxiosInstance;

  constructor(baseURL: string) {
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Intercept requests to add authorization
    this.client.interceptors.request.use((config) => {
      const token = sessionStorage.getItem('auth_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    });

    // Intercept responses to handle errors
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === 401) {
            // Handle unauthorized
            console.warn('Unauthorized - redirecting to login');
          } else if (status === 403) {
            // Handle forbidden
            console.warn('Access forbidden');
          } else if (status === 404) {
            // Handle not found
            console.warn('Resource not found');
          }
        }
        return Promise.reject(error);
      }
    );
  }

  get<T>(url: string, params?: Record<string, any>): Promise<T> {
    return this.client.get(url, { params }).then((res) => res.data);
  }

  post<T>(url: string, data: any): Promise<T> {
    return this.client.post(url, data).then((res) => res.data);
  }

  put<T>(url: string, data: any): Promise<T> {
    return this.client.put(url, data).then((res) => res.data);
  }

  delete<T>(url: string): Promise<T> {
    return this.client.delete(url).then((res) => res.data);
  }
}

export default BaseAPI;
