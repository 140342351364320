import jwtDecode from 'jwt-decode';
import axios, { AxiosInstance } from 'axios';

const AUTH_SESSION_KEY = 'hyper_user';

class APICore {
  private axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Interceptors for request and response
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        let message;
        const { status, data } = error.response || {};

        if (status === 404) {
          // window.location.href = '/not-found';
        } else if (status === 403) {
          window.location.href = '/access-denied';
        } else {
          switch (status) {
            case 401:
              message = 'Invalid credentials';
              break;
            case 403:
              message = 'Access Forbidden';
              break;
            case 404:
              message =
                'Sorry! the data you are looking for could not be found';
              break;
            default:
              message = data?.message || error.message || error;
          }
          return Promise.reject(message);
        }
      }
    );

    // Interceptor for dynamically setting the Authorization header
    this.axiosInstance.interceptors.request.use((config) => {
      const user = APICore.getUserFromSession();
      if (user?.token) {
        config.headers['Authorization'] = `Bearer ${user.token}`;
      }
      config.headers['X-Tenant-Id'] = '3961d749-0b58-4a13-8e02-42e927247f2f';
      return config;
    });
  }

  setAuthorization(token: string | null) {
    if (token)
      this.axiosInstance.defaults.headers.common['Authorization'] =
        'Bearer ' + token;
    else delete this.axiosInstance.defaults.headers.common['Authorization'];
  }

  private static getUserFromSession() {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? JSON.parse(user) : null;
  }

  get(url: string, params?: any) {
    const queryString = params
      ? Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join('&')
      : '';
    return this.axiosInstance.get(
      `${url}${queryString ? `?${queryString}` : ''}`
    );
  }

  getFile(url: string, params?: any) {
    const queryString = params
      ? Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join('&')
      : '';
    return this.axiosInstance.get(
      `${url}${queryString ? `?${queryString}` : ''}`,
      {
        responseType: 'blob',
      }
    );
  }

  create(url: string, data: any) {
    return this.axiosInstance.post(url, data);
  }

  update(url: string, data: any) {
    return this.axiosInstance.put(url, data);
  }

  updatePatch(url: string, data: any) {
    return this.axiosInstance.patch(url, data);
  }

  delete(url: string) {
    return this.axiosInstance.delete(url);
  }

  createWithFile(url: string, data: any) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return this.axiosInstance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  updateWithFile(url: string, data: any) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return this.axiosInstance.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  isUserAuthenticated() {
    const user = APICore.getUserFromSession();
    if (!user || !user.token) return false;

    const decoded: any = jwtDecode(user.token);
    return decoded.exp > Date.now() / 1000;
  }

  setLoggedInUser(session: any) {
    if (session)
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else sessionStorage.removeItem(AUTH_SESSION_KEY);
  }

  getLoggedInUser() {
    return APICore.getUserFromSession();
  }
}

// Export factory function for easier usage
const createAPICore = (baseURL: string) => new APICore(baseURL);

export { APICore, createAPICore };
