import config from 'config';
import { APICore, createAPICore } from './apiCore';

const api = createAPICore(config.IDENTITY_API_URL!);

function absence() {
  const baseUrl = '/api/absence/';
  return api.get(`${baseUrl}`, undefined);
}

function absenceTypes() {
  const baseUrl = '/api/absence/Types';
  return api.get(`${baseUrl}`, undefined);
}

export { absence, absenceTypes };
