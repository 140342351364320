import { APICore } from './apiCore';
import { ILoginRequest } from '../../models/types/types';

const api = new APICore();

function login(params: ILoginRequest) {
  const baseUrl = '/api/v1/identity/login/';
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = '/api/auth/logout/';
  return api.create(`${baseUrl}`, null);
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = '/api/auth/register/';
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = '/forget-password/';
  return api.create(`${baseUrl}`, params);
}

function confirmUser(params: {
  userId: string;
  token: string;
  resetPasswordToken: string;
  password: string;
}) {
  const baseUrl = '/api/auth/confirm/';
  return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
  const baseUrl = '/password/reset/confirm/';
  return api.create(`${baseUrl}`, params);
}

export {
  login,
  logout,
  signup,
  forgotPassword,
  forgotPasswordConfirm,
  confirmUser,
};
